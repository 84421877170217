<template>
    <div>

         <el-main style=" padding-top: 5px;padding-bottom: initial  ">
                <el-form label-width="80px">
                    <el-row>
                        <el-col :lg="24">
                            <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                                <el-row>
                                    <el-col :lg="24" style="line-height: 27px;">
                                        <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：客户期初数</strong>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-col>
                        <el-col :lg="24">
                            <el-row>
                                <el-col :lg="4">
                                    <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini" @click="$refs.AddCompany.addEvent()">添加客户</el-button>
                                    <el-button style=" margin-top: 8px; " id="createBill" type="primary" round size="mini" @click="getList">搜索查询</el-button>
                                </el-col>
                                <el-col :lg="4" :sm="12" :md="4">
                                    <vxe-toolbar class="pull-left" style=" height: 42px; " import custom export ref="xToolbar2">
                                    </vxe-toolbar>
                                </el-col>
                            </el-row>
                        </el-col>

                    </el-row>
                    <el-row>
                        <el-col :lg="24">
                            <vxe-grid ref="xGrid"
                                      size="mini"
                                      :import-config="{
                                        remote: true,
                                        importMethod: this.importMethod,
                                        modes: ['insert'],
                                        type: ['xlsx'],
                                        types: ['xlsx']
                                     }"
                                      :export-config="{type: ['xlsx'], types: ['xlsx'],columns:[{field:'customer_name'},{field:'start'}]}"
                                      border
                                      resizable
                                      show-overflow
                                      show-footer
                                      keep-source
                                      height="650"
                                      highlight-current-row
                                      :footer-method="footerMethod"
                                      :print-config="{}"
                                      :data="tableDataMain"
                                      :columns="tableColumn"
                                      :mouse-config="{selected: true}"
                                      :edit-config="{showIcon:false,trigger: 'dblclick', mode: 'cell', showStatus: false}"
                                      :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                                <!-- 修改应收期初 -->
                                <template #start_edit="{row}">
                                    <vxe-input @blur="saveOrUpdate(row)"
                                            v-model="row.start" type="float" clearable
                                            :controls="false"></vxe-input>
                                </template>
                                <template #operate="{row}">
                                    <template v-if="$refs.xGrid.isActiveByRow(row)">
                                        <vxe-button icon="el-icon-document-checked" status="primary" title="保存" circle @click="saveRowEvent(row)"></vxe-button>
                                    </template>
                                    <template v-else>
                                        <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="$refs.AddCompany.editRowEvent(row.id)"></vxe-button>
                                    </template>
                                    <vxe-button icon="el-icon-delete" title="删除" circle @click="removeRowEvent(row)"></vxe-button>
                                </template>
                                <template #pager>
                                    <vxe-pager
                                            :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                            :current-page.sync="tablePage.currentPage"
                                            :page-size.sync="tablePage.pageSize"
                                            :page-sizes.sync="tablePage.pageSizes"
                                            :total="tablePage.total"
                                            @page-change="handlePageChange">
                                    </vxe-pager>
                                </template>
                            </vxe-grid>
                        </el-col>
                    </el-row>
                </el-form>
        </el-main>
        <el-dialog width="1220px"  :title="isErrorTitle ? '失败' : '导入数据'" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <vxe-grid
                    border
                    resizable
                    show-overflow
                    height="400"
                    ref="xGridImport"
                    :columns="tableColumnImport"
                    :export-config="{type: ['xlsx'], types: ['xlsx']}"
                    :data="tableDataImport"
                    :edit-config="{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil-square-o'}">
            </vxe-grid>
            <div slot="footer" class="dialog-footer">
                <el-button @click="openExportEvent">导 出</el-button>
                <template v-if="isErrorTitle">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                </template>

                <el-button type="primary" @click="saveImport" v-show="!isErrorTitle">确 定</el-button>
            </div>
        </el-dialog>
        <AddCompany ref="AddCompany"></AddCompany>

    </div>
</template>
<script>
    import {filterRender} from "@/utils/gird-filter";

    import AddCompany from "../../components/AddCompany";
    export default {
        components: {AddCompany},
        data() {
            return {
                uuid:'',//导入的uuid
                isErrorTitle:'',
                account:[],
                tableColumn: [
                    //,visible:false
                    {field:'id', width: 100 ,title: '客户编号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/customersStart/searchHeard')
                       }
                   },
                    {field:'sales_man', width: 70 ,title: '业务员' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/customersStart/searchHeard')
                       }
                   },
                    {field:'customer_name', width: 240 ,title: '客户名称' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/customersStart/searchHeard')
                       }
                   },{field:'state', width: 100 ,title: '状态' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/customersStart/searchHeard')
                       }
                   },{field:'start', width: 100 ,title: '期初应收' , formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       },slots:{
                        edit: 'start_edit',
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/customersStart/searchHeard')
                       },
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },

                ],
                tableColumnImport:[
                    {field:'error', width: 250 ,title: '系统提示',slots: {
                            default: ({row}) => {
                                return [
                                    <b style="color: blue">{row.error}</b>
                                ]
                           },
                       }
                   },
                    {field:'customer_name', width: 240 ,title: '客户名称'},
                    {field:'start', width: 100 ,title: '期初应收'}
                ],
                tableDataImport:[],
                tableDataMain:[],
                dialogFormVisible: false,
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 20000,
                    pageSizes: [50,500,1000,2000,20000]
               },
                filterData: {},
                sortData: {"id":"asc"},
                activeName:'first',
                remvoeIds:[],
                banIds:[],
                changeCustomerId:'',
                changeCustomerNamesId:[],
           }
       },
        methods: {
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
                })
                return count;
            },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                    } else {
                        if (column.property === 'start') {
                            sums.push(this.sumNum(data, column.property))
                        } else {
                            sums.push('')
                        }
                    }
                })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
            },
            saveOrUpdate(row){
                this.$axios({
                    method: 'post',
                    url: '/admin/customersStart/saveOrUpdate',
                    params: {
                        id: row.id,
                        start: row.start,
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'success'
                       });
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        this.getList();
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            accountEvent(query){
                this.$axios({
                    method: 'post',
                    url: '/admin/account/getAccountListByName',
                    params: {
                        keyword: query
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.account = response.data.page.list
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            openExportEvent () {
                //this.$refs.xGridImport.value.exportData({type: ['xlsx']})
                console.log('点击导出')
                this.$refs.xGridImport.exportData({
                    type: 'xlsx',
               })
           },
            getList(){
                this.tablePage.currentPage = 1;
                this.getCustomerStartList();
           },
            getCustomerStartList(){
                const loading = this.$loading({
                    lock: true,
                    text: '数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$axios({
                    method:'POST',
                    url:'/admin/customersStart/getCustomerStartList',
                    data:{
                        currentPage: this.tablePage.currentPage,
                        pageSize:this.tablePage.pageSize,
                        sortData:this.sortData,
                        filterData:this.filterData,
                   }
               }).then((response) =>{         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) =>{
                    loading.close();
                    console.log(error)
               });
           },
            saveImport(){
                const loading = this.$loading({
                    lock: true,
                    text: '数据录入中，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method:'post',
                    url:'/admin/customersStart/dbSaveImport',
                    params: {
                        uuid:this.uuid
                   }
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.$message({
                            showClose: true,
                            message: '导入成功',
                            type: 'success'
                       });
                        this.dialogFormVisible = false
                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
                    this.getCustomerStartList();
               }).catch((error) =>{
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
                    console.log(error)
               });
           },
            importMethod ({file}) {
                const loading = this.$loading({
                    lock: true,
                    text: '导入中，请喝杯水，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                const formBody = new FormData()
                formBody.append('file', file)
                this.$axios({
                    method:'post',
                    url:'/admin/customersStart/importExcel',
                    data: formBody,
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if(response.status === 200){
                        this.isErrorTitle=response.data.isError
                        if(response.data.isError == true){
                            this.tableDataImport=response.data.customerListError
                       }else{
                            this.tableDataImport=response.data.customerList

                       }
                        this.uuid=response.data.uuid
                        this.dialogFormVisible = true;

                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) =>{
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
                    console.log(error)
               });
           },
            handlePageChange ({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getCustomerStartList()
           },

            saveRowEvent () {
                const $grid = this.$refs.xGrid
                $grid.clearActived().then(() => {
                    this.gridOptions.loading = true
                    setTimeout(() => {
                        this.gridOptions.loading = false
                        this.$XModal.message({content: '保存成功！', status: 'success'})
                   }, 300)
               })
           },

       },
        created () {

            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2);
                this.getCustomerStartList();
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {display:flex; flex-direction:row;justcontent:space-between}
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }
    /deep/ .el-form-item__error{
        position: initial !important;
   }
    .el-form-item__content .el-input{
        width: 130px;
   }
    /deep/ .vxe-input--panel{
        position: fixed !important;
        left:auto;
   }
    /deep/ .el-input-number .el-input__inner{
        text-align: initial !important;
   }

    .el-form-item__content .el-input-group{
        vertical-align: initial;
   }
</style>
